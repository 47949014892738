<template>
  <div>
    <div class="btn-group">
      <el-tooltip
        class="box-item"
        effect="light"
        :content="$t('t-edit')"
        placement="top"
      >
        <el-button
          type="primary"
          plain
          size="small"
          @click="editColums(props.indexColumns, props.optionColumns)"
        >
          <i class="ri-edit-line"></i>
        </el-button>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="light"
        :content="$t('t-delete')"
        placement="top"
      >
        <el-button
          type="danger"
          plain
          size="small"
          @click="deleteColums(props.indexColumns, props.optionColumns)"
        >
          <i class="ri-delete-bin-5-line"></i>
        </el-button>
      </el-tooltip>
    </div>
    <div class="table table_title">
      <el-tooltip
        class="box-item"
        effect="light"
        :content="props.optionColumns.title_name"
        placement="bottom"
      >
        {{ props.optionColumns.title_name }}
      </el-tooltip>
    </div>
    <div
      class="table table_fontSize"
      :class="{
        textBold: props.optionColumns.bold,
        textItalic: props.optionColumns.italic,
        textUnderline: props.optionColumns.underline,
        hideColumnBgColor: !props.optionColumns.visible,
      }"
      :style="`
        font-size: ${props.optionColumns.font_size}px;
        color: ${props.optionColumns.color};
        text-align: ${props.optionColumns.align}
      `"
    >
      {{
        props.optionColumns.type == "progress"
          ? $t('progress_type_columns')
          : props.optionColumns.type == "rate"
          ? $t('rate_type_columns')
          : $t('none_type_columns')
      }}
    </div>
    <div
      class="table table_fontSize"
      :class="{
        textBold: props.optionColumns.bold,
        textItalic: props.optionColumns.italic,
        textUnderline: props.optionColumns.underline,
        hideColumnBgColor: !props.optionColumns.visible,
      }"
      :style="`
        font-size: ${props.optionColumns.font_size}px;
        color: ${props.optionColumns.color};
        text-align: ${props.optionColumns.align}
      `"
    >
      {{
        props.optionColumns.type == "progress"
          ? $t('progress_type_columns')
          : props.optionColumns.type == "rate"
          ? $t('rate_type_columns')
          : $t('none_type_columns')
      }}
    </div>
    <div
      class="table table_fontSize"
      :class="{
        textBold: props.optionColumns.bold,
        textItalic: props.optionColumns.italic,
        textUnderline: props.optionColumns.underline,
        hideColumnBgColor: !props.optionColumns.visible,
      }"
      :style="`
        font-size: ${props.optionColumns.font_size}px;
        color: ${props.optionColumns.color};
        text-align: ${props.optionColumns.align}
      `"
    >
      {{
        props.optionColumns.type == "progress"
          ? $t('progress_type_columns')
          : props.optionColumns.type == "rate"
          ? $t('rate_type_columns')
          : $t('none_type_columns')
      }}
    </div>
    <div
      class="table table_fontSize"
      :class="{
        textBold: props.optionColumns.bold,
        textItalic: props.optionColumns.italic,
        textUnderline: props.optionColumns.underline,
        hideColumnBgColor: !props.optionColumns.visible,
      }"
      :style="`
        font-size: ${props.optionColumns.font_size}px;
        color: ${props.optionColumns.color};
        text-align: ${props.optionColumns.align}
      `"
    >
      {{
        props.optionColumns.type == "progress"
          ? $t('progress_type_columns')
          : props.optionColumns.type == "rate"
          ? $t('rate_type_columns')
          : $t('none_type_columns')
      }}
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
const props = defineProps({
  optionColumns: Object,
  indexColumns: String,
});
const emit = defineEmits([""]);
const editColums = (index, data) => {
  emit("editColums", index, data);
};
const deleteColums = (index, data) => {
  emit("deleteColums", index, data);
};
</script>
<style scoped>
.table {
  border: thin solid #ebeef5;
  line-height: 40px !important;
  overflow: hidden;
  margin-bottom: 0;
  padding: 5px;
  height: 52px;
}
.table_title {
  background-color: #f3f6f9;
  color: #909399;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table_fontSize {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.textBold {
  font-weight: bold;
}
.textItalic {
  font-style: italic;
}
.textUnderline {
  text-decoration: underline;
}
.btn-group {
  padding: 3px;
  border: thin solid #ebeef5;
}
.hideColumnBgColor {
  background-color: #f3f6f9;
  text-decoration: line-through;
}
</style>