import { defineStore } from "pinia";
import mushroom from "cem-probe-api";
export const ListDashboardPopup = defineStore({
  id: "listDashboardPopup",
  state: () => ({
    data: [],
  }),
  actions: {
    async getAllListDashboardPopup() {
      try {
        const response = await mushroom.dashboard_popup.getAllAsync({},{cacheAge: 1});
        if (response.result) {
          this.data = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  getters: {
    showListDashboardPopup: (state) => {
      return state.data;
    },
  },
});
