<template>
  <div class="my-4">
    <div class="d-flex align-items-center" v-if="!props.infoChartType">
      <loading-circle
        :fontSize="40"
        :opacity="0.5"
        :color="`#0f6a33`"
      ></loading-circle>
      <div style="margin-left: 10px">{{ $t("t-loading-chart-type") }}</div>
    </div>

    <div v-else>
      {{ $t("t-chart-type") }}
      <img alt="" height="50" width="70" :src="linkImage" />
      <span>{{ props.infoChartType }}</span>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import LoadingCircle from "../components/LoadingCircle.vue";

const props = defineProps({
  infoChartType: String,
});
const linkImage = computed(() => {
  return props.infoChartType
    ? require(`../assets/images/chart/${props.infoChartType}.svg`)
    : "";
});
</script>
<style scoped>
span {
  background-color: rgba(41, 156, 219, 0.18);
  padding: 5px 10px;
  border-radius: 5px;
  color: #299cdb;
  margin-left: 10px;
}
</style>