<template>
  <div class="selectIcon" :class="{ disabledSelect: props.disabled }">
    <div class="select" @click="fn_showListIcon()" id="selectBox">
      {{ $t('t-select-icon') }}
    </div>
    <div class="data" v-if="showListIcons" id="dataBox">
      <div
        class="data__list"
        @click="selectIcon(item)"
        v-for="item in props.listIcons"
        :key="item.id"
      >
        <img :src="getIconImage(item)" alt="" class="data__imgs" />
      </div>
    </div>
  </div>
</template>
<script setup>
import mushroom from "cem-probe-api";
import { defineEmits, defineProps, ref, watch } from "vue";
const emit = defineEmits([""]);
const props = defineProps({
  listIcons: Array,
  disabled: Boolean,
});
const showListIcons = ref(false);
const getIconImage = (fileId) => {
  return mushroom.$file.linkBuilder.thumb.id(fileId).build();
};
const selectIcon = (fileId) => {
  showListIcons.value = false;
  emit("setIcon", fileId);
};
const fn_showListIcon = () => {
  props.disabled
    ? (showListIcons.value = false)
    : (showListIcons.value = !showListIcons.value);
};
watch(
  () => props.disabled,
  (newData) => {
    console.log(newData);
    showListIcons.value = false;
  }
);
</script>
<style scoped lang="scss">
.selectIcon {
  position: relative;
  border: thin solid #dcdcdc;
  border-radius: 7px;
  cursor: pointer;
  padding: 0 7px;
  width: 97px;
  text-align: center;
}
.data {
  width: 100%;
  border: thin solid #dcdcdc;
  border-radius: 7px;

  background-color: #fff;
  position: absolute;

  top: 37px;
  left: 0;
  text-align: center;
  z-index: 1;
  height: 123px;
  overflow: hidden;
  overflow-y: auto;
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    text-align: center;
    transition: 0.2s;
    &:hover {
      background-color: #b4b4b437;
    }
  }
  &__imgs {
    height: 20px;
    width: 20px;
    margin: auto;
  }
}
.disabledSelect {
  color: #fff;
  background-color: #b4b4b4;
  cursor: no-drop;
}
</style>
