<template>
  <div :id="'cem-card-item-inner-' + props.item.id" style="width: 100%">
    <div v-if="dataTable?.data?.length > 0" class="group-text">
      <table class="table">
        <tbody>
          <tr v-for="row in dataTable.data" :key="row.id">
            <th scope="row" class="textData textData__left">
              {{ row[0] }}
            </th>
            <th scope="row" class="textData textData__right">
              {{ renderData(row[1]) }}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else></div>
  </div>
</template>

<script setup>
import { defineProps, reactive, watch } from "vue";

import { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";

const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
});

const dataTable = reactive({ data: [], columns: [] });
const renderData = (data) => {
  if (!data || data === null || data === undefined || data === "") {
    return "-";
  } else return data;
};
watch(
  () => props.itemData,
  (newData) => {
    dataTable.data = newData.data;
  }
);
</script>
<style scoped lang="scss">
.textData {
  font-size: 14px;
  color: #606266;
  font-weight: normal;
  &__left {
    text-align: left;
  }
  &__right {
    text-align: right;
  }
}
.table > :not(caption) > * > * {
  border-bottom: 2px dashed #d9e5ff;
}
.group-text {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
