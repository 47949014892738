<template>
  <div :id="'cem-card-item-inner-' + props.item.id" class="network-card">
    <div v-if="dataTable.data.length > 0" class="w-100">
      <div class="d-none">{{ A2 }}</div>
      <div class="network">
        <div class="block1">
          <div class="block1-item block-device text-center">
            <span>UE</span>
            <div class="block-devices">
              <img
                src="@/assets/images/network-devices.svg"
                alt="network devices"
              />
            </div>
          </div>
          <div class="block-modem">
            <span>Modem</span>
            <div class="block-modems">
              <div>
                <img src="@/assets/images/router.svg" alt="modem" />
              </div>
            </div>
          </div>
          <div class="block1-item block1-item-position block-capturing">
            <div>
              <p>CAPTURING</p>
              <img src="@/assets/images/node.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="block2 block2-internet">
          <div class="d-flex justify-content-between align-items-center">
            <div class="block2-item position-relative">
              <div class="block-bras">
                <span>BRAS</span>
                <div class="block2-item position-relative">
                  <img src="@/assets/images/internet.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="block2-item block2-devices segments-text">
              <div
                class="block-devices-server"
                :class="{
                  isDisplayFlex: dataTable.urlIcon?.length > 0,
                  isTextCenter: dataTable.urlIcon?.length == 0,
                }"
              >
                <img
                  src="@/assets/images/server.svg"
                  class="server-img"
                  alt="server"
                />
                <div
                  class="d-flex flex-column"
                  v-if="dataTable.urlIcon?.length > 0"
                >
                  <div
                    class="icons-service"
                    v-for="imgUrl in dataTable.urlIcon"
                    :key="imgUrl.id"
                  >
                    <img
                      :src="imgUrl"
                      alt="server"
                      height="30"
                      width="30"
                      style="margin: 5px"
                    />
                  </div>
                </div>
              </div>
              <p class="segments" v-if="dataTable.nameServer?.length > 0">
                Server {{ dataTable.nameServer }}
              </p>
            </div>
          </div>
          <div class="group-lines">
            <div class="line-X">
              <div
                class="line"
                :style="{ 'margin-top': 20 + 'px' }"
                v-for="item in dataTable.numServiceX"
                :key="item.id"
              >
                {{ item.value }}
              </div>
            </div>
            <div class="line-group-Y">
              <div class="line-Y">
                <div
                  class="line"
                  :style="{ 'margin-top': 20 + 'px' }"
                  v-for="item in dataTable.numServiceY"
                  :key="item.id"
                >
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="line-bg"></div>
      </div>
    </div>
    <div v-else></div>
  </div>
</template>

//
<script setup>
import { defineProps, onMounted, reactive, watch, ref, computed } from "vue";

import mushroom, { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";

const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
  dataImageUpdate: Array,
});

const dataTable = reactive({
  data: [],
  columns: [],
  nameServer: "",
  numServiceX: [],
  numServiceY: [],
  urlIcon: [],
});
const isErrNetwork2s = ref(false);

watch(
  () => props.itemData,
  (newData) => {
    dataTable.numServiceX = [];
    dataTable.numServiceY = [];
    dataTable.data = newData.data;
    dataTable.numServiceX.push({
      title: dataTable.data[0][0],
      value: dataTable.data[0][0],
    });
    dataTable.numServiceY.push({
      title: dataTable.data[0][1],
      value: dataTable.data[0][1],
    });
  }
);

const A2 = computed(() =>
  props.dataImageUpdate.forEach(async (item) => {
    dataTable.urlIcon = [];
    const response = await mushroom.service.findByIdAsync({
      id: item,
      fields: "name,avatar_id",
    });
    dataTable.nameServer = response.result?.name;
    if (response.result?.avatar_id?.length > 0) {
      const imgSrc = mushroom.$file.linkBuilder.thumb
        .id(response.result.avatar_id)
        .build();
      dataTable.urlIcon.push(imgSrc);
    }
  })
);

onMounted(async () => {
  if (props.item.type === "err-network-2segments") {
    isErrNetwork2s.value = true;
  } else isErrNetwork2s.value = false;
  props.item.network.service_ids.forEach(async (item) => {
    const response = await mushroom.service.findByIdAsync({
      id: item,
      fields: "name,avatar_id",
    });
    dataTable.nameServer = response.result?.name;
    if (response.result?.avatar_id?.length > 0) {
      const imgSrc = mushroom.$file.linkBuilder.thumb
        .id(response.result.avatar_id)
        .build();
      dataTable.urlIcon.push(imgSrc);
    }
  });
});
</script>
<style scoped>
.line-Y {
  width: calc(100% - 6px);
  margin-left: -49px;
}

.network {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 15px;
}

.block-internet {
  position: absolute;
  left: 45%;
  top: 16px;
}

.block2-internet::before {
  display: none;
}
</style>
