<template>
  <div :id="'cem-card-item-inner-' + props.item.id" style="width: 100%"></div>
</template>

<script setup>
import { defineProps, onMounted, watch, ref, reactive } from "vue";
import MethodService from "../../service/MethodService";
import Data from "../CardItemsConfig/dataFormCardItemsConfig";
import * as echarts from "echarts";
import { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";
const colorDefault = reactive({
  value: MethodService.copyObject(Data.colorDefault),
});
const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
  dataChartOption: Object,
});
const colorOptions = ref([]);
const chartTitle = ref();
const optionLegend = ref({});
const chart_link = ref("");
const renderColorLineRadar = () => {
  let colorLines = [];
  if (!colorOptions.value || colorOptions.value.length == 0) {
    colorLines = colorDefault.value;
  } else {
    colorLines = [];
    colorOptions.value.forEach((color) => {
      if (color.line_color) colorLines.push(color.line_color);
    });
    colorLines = colorLines.concat(colorDefault.value);
  }
  return colorLines;
};
const renderRadarIndicator = () => {
  let radarIndicator = [];
  let arr = [];
  props.itemData.columns.forEach((item) => {
    radarIndicator.push(item.field_name);
  });
  radarIndicator.shift();

  radarIndicator.forEach((item, index) => {
    if (props.dataChartOption) {
      arr.push({
        name: item,
        max: props.dataChartOption.data_serials[index]?.radar_max || undefined,
      });
    } else {
      arr.push({
        name: item,
        max: props.item.chart.data_serials[index]?.radar_max || undefined,
      });
    }
  });

  return arr;
};
const render = (data) => {
  if (!data || data.length == 0) return;
  let dataLegend = [];
  let dataPie = [];
  data?.data.forEach((item) => {
    let arr = [...item];
    arr.shift();
    dataPie.push({ value: arr, name: item[0]?.toString() });
    dataLegend.push(item[0]?.toString());
  });
  const chartDom = document.getElementById(
    `cem-card-item-inner-${props.item.id}`
  );

  const myChart = echarts.init(chartDom);

  const option = {
    grid: {
      left: 40,
      top: chartTitle.value.show ? 40 : 20,
      right: 20,
      bottom: 20,
    },
    title: chartTitle.value.show
      ? {
          text: chartTitle.value.text,
          textStyle: {
            fontSize: chartTitle.value.textStyle.fontSize,
            color: chartTitle.value.textStyle.color,
          },
        }
      : null,
    tooltip: {
      trigger: "item",
      backgroundColor: "#1c1c1c",
      borderColor: "#1C1C1C",
      borderRadius: 14,
      textStyle: {
        color: "#fff",
        fontSize: 12,
      },
    },
    legend: {
      data: dataLegend,
      orient: optionLegend.value?.orient
        ? optionLegend.value.orient
        : "horizontal",
      type: optionLegend.value?.orient == "vertical" ? "scroll" : "",
      left: optionLegend.value.align?.horizontal || "center",
      top: optionLegend.value.align?.vertical || "bottom",
      textStyle: {
        color: "#1c1c1c",
      },
    },
    radar: {
      indicator: renderRadarIndicator(),
      // shape: 'circle',
    },
    series: [
      {
        name: data.columns[1].title,
        type: "radar",
        radius: ["40%", "70%"],
        center: ["50%", "50%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        data: dataPie,
        color: renderColorLineRadar(),
      },
    ],
    // color: colorChart.value.length > 0 ? colorChart.value : colorDefault.value,
  };

  myChart.setOption(option);
  myChart.getZr().on("click", function (event) {
    if (!event.target) {
      if (chart_link.value) window.open(chart_link.value);
    }
  });
};

onMounted(() => {
  chartTitle.value = props.dataChartOption
    ? props.dataChartOption.title
    : props.item.chart.title;
  colorOptions.value = props.dataChartOption
    ? props.dataChartOption.data_serials
    : props.item.chart.data_serials;
  optionLegend.value = props.dataChartOption
    ? props.dataChartOption.legend
    : props.item.chart.legend;
  chart_link.value = props.dataChartOption
    ? props.dataChartOption.chart_link
    : props.item.chart.chart_link;
  render(props.itemData);
});
watch(
  () => props.itemData,
  (data) => render(data)
);
</script>
<style scoped></style>
