<template>
  <div :id="'cem-card-item-inner-' + props.item.id" class="network-card">
    <div v-if="dataTable.data.length > 0" class="w-100">
      <div class="d-none">{{ A2 }}</div>
      <div class="network">
        <div class="block1">
          <div class="block1-item block-device text-center">
            <span>UE</span>
            <div class="block-devices">
              <img
                src="@/assets/images/network-devices.svg"
                alt="network devices"
              />
            </div>
          </div>
          <div class="block-modem">
            <span>Modem</span>
            <div class="block-modems">
              <div>
                <img src="@/assets/images/router.svg" alt="modem" />
              </div>
            </div>
          </div>
          <div class="block1-item block1-item-position block-capturing">
            <div>
              <p>CAPTURING</p>
              <img src="@/assets/images/node.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="block2 block2-internet">
          <div class="d-flex justify-content-between align-items-center">
            <div class="block-bras">
              <span>BRAS</span>
              <div class="block2-item position-relative">
                <img src="@/assets/images/internet.svg" alt="" />
              </div>
            </div>

            <div class="block2-item block2-devices segments-text">
              <p class="multi-segments">Server of Service</p>
              <div
                class="block-devices-server"
                :class="{
                  isDisplayFlex: dataTable.urlIcon?.length > 0,
                  isTextCenter: dataTable.urlIcon?.length == 0,
                }"
              >
                <img
                  src="@/assets/images/server.svg"
                  class="server-img"
                  alt="server"
                />
                <div
                  class="d-flex flex-column"
                  v-if="dataTable.urlIcon?.length > 0"
                >
                  <div
                    class="icons-service"
                    v-for="imgUrl in dataTable.urlIcon"
                    :key="imgUrl.id"
                  >
                    <img
                      :src="imgUrl"
                      alt="server"
                      height="30"
                      width="30"
                      style="margin: 5px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="group-lines">
            <div class="line-X">
              <div
                class="line"
                :style="{ 'margin-top': 20 + 'px' }"
                v-for="item in dataTable.numServiceX"
                :key="item.id"
              >
                {{ item.title.replace("_X_Value", "") }} : {{ item.value }}
              </div>
            </div>
            <div class="line-group-Y">
              <div class="line-Y">
                <div
                  class="line"
                  :style="{ 'margin-top': 20 + 'px' }"
                  v-for="item in dataTable.numServiceY"
                  :key="item.id"
                >
                  {{ item.title.replace("_Y_Value", "") }} : {{ item.value }}
                </div>
              </div>

              <div class="data-line">
                <div
                  class="group-line"
                  :style="{ 'margin-top': 20 + 'px' }"
                  v-for="item in dataTable.numServiceZ"
                  :key="item.id"
                >
                  {{ item.title.replace("_Z_Value", "") }} : {{ item.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="line-bg"></div>
      </div>
    </div>
    <div v-else></div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, reactive, watch, computed } from "vue";
import mushroom, { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";

const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
  dataImageUpdate: Array,
});

const dataTable = reactive({
  data: [],
  nameServer: [],
  urlIcon: [],
  numServiceX: [],
  numServiceY: [],
  numServiceZ: [],
});

watch(
  () => props.itemData,
  (newData) => {
    let arrayValue = newData.data?.[0]?.slice(); // copy mảng
    let arrayTitle = newData.columns?.slice(); // copy mảng
    dataTable.data = [];
    dataTable.numServiceX = [];
    dataTable.numServiceY = [];
    dataTable.numServiceZ = [];
    arrayValue.map((item, index) => {
      dataTable.data.push({
        title: arrayTitle[index].title,
        value: item,
      });
    });
    dataTable.data.forEach((item) => {
      if (item.title.includes("_X_Value")) {
        dataTable.numServiceX.push({
          title: item.title,
          value: item.value,
        });
      }
      if (item.title.includes("_Y_Value")) {
        dataTable.numServiceY.push({ title: item.title, value: item.value });
      }
      if (item.title.includes("_Z_Value")) {
        dataTable.numServiceZ.push({ title: item.title, value: item.value });
      }
    });
  }
);

onMounted(async () => {
  props.item.network.service_ids.forEach(async (item) => {
    const response = await mushroom.service.findByIdAsync({
      id: item,
      fields: "name,avatar_id",
    });
    dataTable.nameServer.push(response.result?.name);

    if (response.result?.avatar_id?.length > 0) {
      const imgSrc = mushroom.$file.linkBuilder.thumb
        .id(response.result.avatar_id)
        .build();
      dataTable.urlIcon.push(imgSrc);
    }
  });
});
const A2 = computed(() =>
  props.dataImageUpdate.forEach(async (item) => {
    dataTable.urlIcon = [];
    const response = await mushroom.service.findByIdAsync({
      id: item,
      fields: "name,avatar_id",
    });

    if (response.result?.avatar_id?.length > 0) {
      const imgSrc = mushroom.$file.linkBuilder.thumb
        .id(response.result.avatar_id)
        .build();
      dataTable.urlIcon.push(imgSrc);
    }
  })
);
</script>

<style scoped>
.block-bras {
  top: -6px;
}
.data-line {
  margin-left: 2px;
}

.group-lines {
  display: flex;
  align-items: center;
}
.line-group-Y {
  width: calc(100% - 62px);
}
</style>
