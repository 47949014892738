<template>
  <div class="dashboard-card" :id="props.cardId">
    <div
      class="card-header d-flex justify-content-between card-header-55"
      :class="{
        hideCardHeader:
          props.headerOptions?.is_hidden && storeChangeEdit.showEdit === false,
      }"
      :style="`background-color: ${state.ui.headerCard.backgroundColor} `"
    >
      <div class="d-flex align-items-center w-100">
        <div class="title-card" :style="`color: ${state.ui.headerCard.color} `">
          {{ titleCard }}
        </div>
        <div class="mini-card" @click="miniCard(props.cardId)">
          <!-- <i
            class="ri-arrow-up-s-line mt-1"
            :id="`mini${props.cardId}`"
            :style="`color: ${state.ui.headerCard.color} `"
          ></i> -->
          <img
            :id="`mini${props.cardId}`"
            src="../assets/icon/mini-card.svg"
            alt="mini-card"
          />
        </div>
      </div>

      <div
        class="button-action d-flex align-items-center"
        v-if="storeChangeEdit.showEdit === true"
      >
        <div
          v-show="props?.typeDashboard !== 'uc'"
          class="action action-sort"
          :title="t('t_sort')"
          @click="showSortCardTable"
        >
          <i
            class="ri-drag-move-2-fill mt-1"
            :style="`color: ${state.ui.headerCard.color} `"
          ></i>
        </div>
        <div
          class="action action-update button-update"
          :title="`${$t('t-option-card')}`"
          @click="showOptionsCard"
        >
          <i
            class="ri-settings-4-line mt-1"
            :style="`color: ${state.ui.headerCard.color} `"
          ></i>
        </div>
        <div
          class="action action-hide"
          :title="t('t-add-chart')"
          @click="visiableCardItemConfig(/*props.cardId, props.cardTitle*/)"
        >
          <i
            class="ri-add-line mt-1"
            :style="`color: ${state.ui.headerCard.color} `"
          ></i>
        </div>

        <div
          v-show="props?.typeDashboard !== 'uc'"
          class="action action-hide action-close box-btn-close btn-close-cards m-1"
          :title="t('t_delete')"
          id=""
          @click="removeCard(props.cardId, props.cardTitle)"
        >
          <i
            class="ri-delete-bin-line mt-1"
            :style="`color: ${state.ui.headerCard.color} `"
          ></i>
        </div>
      </div>
    </div>
    <div
      class="grid-stack grid-stack-nested grid-stack-animate"
      :class="`sub${props.cardId}`"
    ></div>
    <div
      class="no-chart-card-item"
      :class="`noChart${props.cardId}`"
      v-if="isHaveCardItem"
    >
      <div class="general-alert" style="">
        <i class="mdi mdi-chart-bar-stacked mx-2"></i>
        {{ $t("t-no-chart-data") }}
      </div>
    </div>
    <div v-if="!isHaveCardItem">
      <div v-for="item in cardData.cardItems" :key="item.id">
        <card-item
          :heightCardContent="cardData.height"
          :itemData="item"
          :cardTitle="props.cardTitle"
          :grid="cardData.grid"
          :visiableCardItemConfig="showCardItemConfig"
          :actionDeleteOption="props.headerOptions?.is_hidden"
          @reloadGridDashboardFromCardItems="reloadGridDashboardFromCardItems"
          @reloadCardItems="reloadCardItems"
          @countCardItemHaveData="countCardItemHaveData"
          @uploadCardItem="getAllCardItem"
        />
      </div>
    </div>
  </div>
  <card-item-config
    :cardIdToCardItemsConfig="props.cardId"
    :cardTitleToCardItemsConfig="props.cardTitle"
    :showCardItemConfig="state.ui.cardItemConfig.visible"
    :stepNumberForCardItem="state.ui.cardItemConfig.step"
    :typeShowCardItemConfig="state.ui.cardItemConfig.type"
    :listCardItem="cardData.cardItems"
    :sumWidthCardItem="sumWidthCardItem"
    @closeDialogCardItemsConfig="closeDialogCardItemsConfig"
    @addNewCardItems="addNewCardItems"
    @updateNewCardItems="updateNewCardItems"
    @changeTypeShowCardItemConfig="changeTypeShowCardItemConfig"
    @emitGetAllCardItem="emitGetAllCardItem"
  ></card-item-config>
  <dialog-options-card
    :titleCard="props.cardTitle"
    :showDialogOptionsCard="state.ui.showDialogCardOptions"
    :optionsCard="props.headerOptions"
    @closeDialogOptionsCard="closeDialogOptionsCard"
    @updateOptionsCardFromDialog="updateOptionsCardFromDialog"
  >
  </dialog-options-card>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, reactive, ref, watch } from "vue";
import mushroom from "cem-probe-api";
import { GridStack } from "gridstack";
import toastr from "toastr";
import MethodService from "../service/MethodService";
import CardItem from "./CardItemComponent.vue";
import { changeEdit } from "../stores/changeEdit";
import CardItemConfig from "./CardItemsConfig/CardItemsConfig.vue";
import DialogOptionsCard from "../components/DialogOptionsCard.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const storeChangeEdit = changeEdit();
import { datePicker } from "../stores/datePicker";
const storeDatePicker = datePicker();
import Swal from "sweetalert2";
const emit = defineEmits([""]);
const props = defineProps({
  cardId: String,
  cardTitle: String,
  cardIndex: String,
  cardMaxIndex: String,
  dashboardType: String,
  headerOptions: Object,
  typeDashboard: String,
});
const isHaveCardItem = ref(false);
const countCardItem = ref(0);
const titleCard = ref([]);
const showCardItemConfig = ref(false);
//const cardIdToCardItemsConfig = ref("");
const cardTitleToCardItemsConfig = ref("");
const isDashboardUC = ref(false);
const sumHeightCardItem = ref(0);
const cardData = reactive({
  cardItems: [],
  grid: null,
  height: "",
});
const sumWidthCardItem = ref(0);

const state = reactive({
  ui: {
    cardItemConfig: {
      visible: false,
      step: "",
      type: "",
    },
    headerCard: {
      color: "#606266",
      backgroundColor: "#fff",
    },
    showDialogCardOptions: false,
  },
});
const arr = ref([]);
const countCardItemHaveData = (data, id) => {
  arr.value.push(id);

  if (arr.value.length == cardData.cardItems.length) {
    if (!storeChangeEdit.showEdit) {
      miniCard(id);
    }
  }
};
const emitGetAllCardItem = async () => {
  await getAllCardItem();
};
const updateNewCardItems = async (data) => {
  // update card-item copy từ dashboard card

  const card_item_obj_copy = { ...data };
  let arrSqlTableFormart = [];
  if (data.sql_table_formats?.length > 0) {
    data.sql_table_formats.includes("\n")
      ? (arrSqlTableFormart = data.sql_table_formats.split("\n"))
      : (arrSqlTableFormart = data.sql_table_formats.split());
  } else arrSqlTableFormart = [];
  card_item_obj_copy.sql_table_formats = arrSqlTableFormart;

  let arrChartSqlTableFormart = [];
  if (data.chart.alert.sql_table_formats?.length > 0) {
    data.chart.alert.sql_table_formats.includes("\n")
      ? (arrChartSqlTableFormart =
          data.chart.alert.sql_table_formats.split("\n"))
      : (arrChartSqlTableFormart = data.chart.alert.sql_table_formats.split());
  } else arrChartSqlTableFormart = [];
  card_item_obj_copy.chart.alert.sql_table_formats = arrChartSqlTableFormart;

  try {
    const response = await mushroom.card_item.partialUpdateAsync(
      card_item_obj_copy
    );
    if (response.result == card_item_obj_copy.id) {
      toastr.success(t("toastr_add_card_success"));
      getAllCardItem();
    } else toastr.warning(t("toastr_not_change_warning"));
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    state.ui.cardItemConfig.visible = false;
    state.ui.cardItemConfig.step = 1;
  }
};
const changeTypeShowCardItemConfig = (data) => {
  state.ui.cardItemConfig.type = data; // là 'update'
};
const showOptionsCard = () => {
  state.ui.showDialogCardOptions = true;
};
const closeDialogOptionsCard = () => {
  // ẩn dialog cấu hình card
  state.ui.showDialogCardOptions = false;
};
const updateOptionsCardFromDialog = async (dataOptionsCard) => {
  const card_object = {
    id: props.cardId,
    title: dataOptionsCard.title,
    header: {
      is_hidden: dataOptionsCard.is_hidden,
      color: dataOptionsCard.color,
      backgroundColor: dataOptionsCard.backgroundColor,
    },
  };
  try {
    const response = await mushroom.card.partialUpdateAsync(card_object);
    if (response.result == props.cardId) {
      toastr.success(t("t_update_card_config_success"));
      state.ui.showDialogCardOptions = false;
      emit("reRenderCardAfterOtionCard");
    } else toastr.warning(t("toastr_not_change_warning"));
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const reloadCardItems = async () => {
  //await getAllCardItem();
  emit("reRenderCard");
};
const reloadGridDashboardFromCardItems = () => {
  emit("reloadGridDashboard");
};

const showSortCardTable = () => {
  emit("showSortCardTable");
};
const miniCard = (cardId) => {
  const domCard = document.getElementById(cardId);
  const domSubCard = document.getElementsByClassName("sub" + cardId);
  const domMiniCard = document.getElementById("mini" + cardId);
  const domNoChart = document.querySelector(".noChart" + cardId);
  domMiniCard.style.transition = "all .3s";
  if (domCard.style.minHeight !== "0px") {
    domMiniCard.style.transform = "rotate(180deg)";
    domCard.style.minHeight = "0px";
    if (domNoChart) domNoChart.style.display = "none";
    domSubCard[0].style.display = "none";
  } else {
    domCard.style.minHeight = "300px";
    domMiniCard.style.transform = "rotate(0deg)";
    domSubCard[0].style.display = "block";
    if (cardData.cardItems.length == 0 && domNoChart)
      domNoChart.style.display = "flex";
  }
};

const visiableCardItemConfig = () => {
  cardTitleToCardItemsConfig.value = props.cardTitle;
  state.ui.cardItemConfig.visible = true;
  state.ui.cardItemConfig.step = 1;
  state.ui.cardItemConfig.type = "create";
};

const removeCard = (idCard, titleCard) => {
  if (countCardItem.value > 1) {
    toastr.warning(t("toastr_delete_card_required"));
  } else {
    Swal.fire({
      title: t("t-warning"),
      html: `<b>${titleCard}</b> ${t("t_will_delete")}`,
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: t("swal_accept"),
      cancelButtonText: t("swal_cancel"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await mushroom.card.deleteAsync({
            id: idCard,
          });
          if (response.result == 1)
            toastr.success(t("toastr_delete_card_success"));
          emit("reloadGridDashboard");
        } catch (e) {
          console.error("Có lỗi: %o", e);
          MethodService.showError(e.code);
        }
      }
    });
  }
};

let grid = null;
const getAllCardItem = async () => {
  // emit("reloadingData");
  cardData.cardItems = [];
  sumWidthCardItem.value = 0;
  try {
    const response = await mushroom.card_item.listAsync({
      filters: ["card_id=" + props.cardId],
    });
    if (response.result.length == 0) {
      isHaveCardItem.value = true;
    } else {
      isHaveCardItem.value = false;
      countCardItem.value = response.result.length;
      grid.removeWidget();
      cardData.grid = grid;
      response.result.forEach((item) => {
        cardData.cardItems.push(item);
        sumWidthCardItem.value += item.width;
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  } finally {
    emit("unloadingData");
  }
};

const updatePositionAndSizeCardItem = async (cardItem) => {
  cardData.height = 0;
  const card_item_object = {
    card_id: props.cardId,
    id: cardItem.id,
    x: cardItem.x,
    y: cardItem.y,
    width: cardItem.w,
    height: cardItem.h,
  };
  let width = document.body.clientWidth;
  if (width > 768) {
    try {
      const response = await mushroom.card_item.partialUpdateAsync(
        card_item_object
      );
      if (response.result == cardItem.id) {
        let height = document.querySelector(".table-body");
        if (height) {
          cardData.height = height.offsetHeight;
        }
      }
    } catch (e) {
      console.error("Có lỗi: %o", e);
      MethodService.showError(e.code);
    }
  }
};

const closeDialogCardItemsConfig = () => {
  state.ui.cardItemConfig.visible = false;
};

const addNewCardItems = (data) => {
  state.ui.cardItemConfig.visible = false;
  addCardItemAsync(data);
};

const addCardItemAsync = async (data) => {
  const node = {
    x: 0,
    y: 0,
    h: 4,
    w: 4,
    ...data,
  };
  let arrSqlTableFormart = [];
  if (data.sql_table_formats?.length > 0) {
    data.sql_table_formats.includes("\n")
      ? (arrSqlTableFormart = data.sql_table_formats.split("\n"))
      : (arrSqlTableFormart = data.sql_table_formats.split());
  } else arrSqlTableFormart = [];

  let arrChartSqlTableFormart = [];
  if (data.chart.alert.sql_table_formats?.length > 0) {
    data.chart.alert.sql_table_formats.includes("\n")
      ? (arrChartSqlTableFormart =
          data.chart.alert.sql_table_formats.split("\n"))
      : (arrChartSqlTableFormart = data.chart.alert.sql_table_formats.split());
  } else arrChartSqlTableFormart = [];

  const card_item_object = {
    card_id: props.cardId,
    type: node.type,
    title: node.title,
    x: node.x,
    y: node.y,
    width: node.w,
    height: node.h,
    table_config_id: node.table_config_id,
    table: {
      hasNo: node.table.hasNo,
      isPaging: data.table.isPaging,
      format: node.table.format,
    },
    chart: {
      title: {
        show: node.chart.title?.show || null,
        text: node.chart.title?.text || null,
        textStyle: {
          fontSize: node.chart.title.textStyle?.fontSize || null,
          color: node.chart.title.textStyle?.color || null,
        },
      },
      legend: {
        show: node.chart.legend.show,
        orient: node.chart.legend.orient,
        align: {
          horizontal: node.chart.legend.align.horizontal || "center",
          vertical: node.chart.legend.align.vertical || "bottom",
        },
        left: node.chart.legend.left,
        top: node.chart.legend.top,
        right: node.chart.legend.right,
        bottom: node.chart.legend.bottom,
      },
      x_axis: {
        min: node.chart.x_axis?.min || null,
        max: node.chart.x_axis?.max || null,
      },
      y_axis: {
        min: node.chart.y_axis?.min || null,
        max: node.chart.y_axis?.max || null,
        axisLabel: {
          rotate: node.chart.y_axis.axisLabel?.rotate || null,
        },
      },
      alert: {
        sql: node.chart.alert.sql,
        sql_table_formats: arrChartSqlTableFormart,
        table_config_id: node.chart.alert.table_config_id,
      },
      color: node.chart.color?.length > 0 ? node.chart.color : [],
      borderRadiusType: node.chart.borderRadiusType,
      smooth: node.chart.smooth,
      chart_link: data.chart.chart_link,
      multi_color_for_single_bar_chart:
        data.chart.multi_color_for_single_bar_chart,
      data_serials: data.chart.data_serials,
    },
    general: {
      header: {
        is_hidden: node.general.header.is_hidden,
        color: node.general.header.color,
        backgroundColor: node.general.header.backgroundColor,
      },
    },
    network: { service_ids: node.network.service_ids },
    sql: node.sql,
    sql_table_formats: arrSqlTableFormart,
    // optional - Các thuộc tính của header
    settings: {
      add_start_days: data.settings?.add_start_days ?? null,
      add_end_days: data.settings?.add_end_days ?? null,
    },
  };
  try {
    const responseHeightCardItem = await mushroom.card_item.listAsync({
      filters: ["card_id=" + props.cardId],
    });
    responseHeightCardItem.result.forEach((item) => {
      sumHeightCardItem.value += item.height;
    });
    card_item_object.y = sumHeightCardItem.value;
    const response = await mushroom.card_item.createAsync(card_item_object);
    data.id = response.result;
    if (response.result) {
      toastr.success(t("toastr_add_success"));
      getAllCardItem();
      state.ui.cardItemConfig.step = 1;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};

watch(
  () => storeDatePicker.showDatePicker,
  () => {
    emit("reRenderCard");
  }
);
onMounted(async () => {
  const select = ".sub" + props.cardId;
  // GridStack.init({column: 24}); // không dùng do bị mất resize bên trái
  grid = GridStack.addGrid(document.querySelector(select), {
    resizable: {
      handles: "e,se,s,sw,w",
    },
    disableOneColumnMode: true,
    column: 48,
  });
  await getAllCardItem();

  if (storeChangeEdit.showEdit) {
    grid.on("change", function (e, items) {
      if (items?.length > 0)
        items.forEach(async function (item) {
          await updatePositionAndSizeCardItem(item);
        });
    });
  }
  props.dashboardType == "uc"
    ? (isDashboardUC.value = true)
    : (isDashboardUC.value = false);
  titleCard.value = props.cardTitle;
  state.ui.headerCard.backgroundColor = props.headerOptions?.backgroundColor;
  state.ui.headerCard.color = props.headerOptions?.color;
});
</script>

<style lang="scss">
.grid-stack > .grid-stack-item {
  $gridstack-columns: 48; // default 24

  min-width: 100% / $gridstack-columns;

  @for $i from 0 through $gridstack-columns {
    &[gs-w="#{$i}"] {
      width: (100% / $gridstack-columns) * $i;
    }

    &[gs-x="#{$i}"] {
      left: (100% / $gridstack-columns) * $i;
    }

    &[gs-min-w="#{$i}"] {
      min-width: (100% / $gridstack-columns) * $i;
    }

    &[gs-max-w="#{$i}"] {
      max-width: (100% / $gridstack-columns) * $i;
    }
  }
}
</style>

<style>
.dashboard-card {
  width: 100%;
  min-height: 300px;
  background-color: #f4f4f4;
  margin-bottom: 10px;
  border-radius: 5px;
}

.card-header .el-input__wrapper,
.card-header .el-input__wrapper:hover {
  box-shadow: unset;
}

.el-input.is-disabled .el-input__wrapper {
  background-color: unset;
  box-shadow: unset;
}

.title-card {
  font-size: 24px;
  color: #a4a4a4;
  font-weight: 400;
  line-height: 32px;
}

.no-chart-card-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 238px;
}

.title-card .el-input__wrapper {
  background-color: transparent;
}

.mini-card {
  margin-left: 10.75px;
  cursor: pointer;
}

.card-header {
  padding: 0 8px;
}
</style>
