<template>
  <div :id="'cem-card-item-inner-' + props.item.id" style="width: 100%">
    <div v-if="dataTable.data.length > 0" class="time-line">
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :timestamp="activity.timestamp"
          :color="activity.color"
        >
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <div v-else></div>
  </div>
</template>

//
<script setup>
import { defineProps, reactive, watch, ref } from "vue";

import { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";

const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
});

const dataTable = reactive({ data: [], columns: [] });
const activities = ref([]);
watch(
  () => props.itemData,
  (newData) => {
    dataTable.columns = newData.columns;
    if (newData.data?.length > 0) {
      dataTable.data = newData.data;

      dataTable.data.forEach((item) => {
        activities.value.push({
          content: item[1],
          timestamp: item[0],
        });
      });
      activities.value[0].color = "#0bbd87";
    }
  }
);
</script>
<style scoped>
.time-line {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
