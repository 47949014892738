<script setup>
import { defineEmits, defineProps, reactive, ref, watch } from "vue";
const props = defineProps({
  optionsCard: Object,
  showDialogOptionsCard: Boolean,
  titleCard: String,
});
const showDialog = ref(false);
const loadingBtn = ref(false);
const dataOptionsCard = reactive({
  title: props.titleCard || "",
  color: props.optionsCard?.color || null,
  backgroundColor: props.optionsCard?.backgroundColor || null,
  is_hidden: props.optionsCard?.is_hidden || false,
});
const emit = defineEmits([""]);
const handleClose = () => {
  emit("closeDialogOptionsCard");
};
const updateOptionsCard = () => {
  loadingBtn.value = true;
  emit("updateOptionsCardFromDialog", dataOptionsCard);
  setTimeout(() => {
    loadingBtn.value = false;
  }, 1000);
};
watch(
  () => props.showDialogOptionsCard,
  (newValue) => {
    showDialog.value = newValue;
  }
);
watch(
  () => props.optionsCard,
  (newOtions) => {
    dataOptionsCard.color = newOtions?.color;
    dataOptionsCard.backgroundColor = newOtions?.backgroundColor;
  }
);
</script>

<template>
  <el-dialog
    v-model="showDialog"
    :title="`${$t('t-option-card')} ${props.titleCard}`"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="optionsCard">
      <div class="optionsCard__title">
        <!-- <label for="">{{ $t("t-title-card-item") }}:</label> -->
        <label for="">{{ $t('t-title-card-item') }}:</label>
        <div class="">
          <span class="">{{ $t('t-hide-card-header') }} &nbsp;&nbsp;</span>
          <el-switch v-model="dataOptionsCard.is_hidden" />
        </div>
      </div>
      <el-input v-model="dataOptionsCard.title" placeholder="Please input" clearable />

      <div class="optionsCard__color">
        <div class="optionsCard__color-item">
          <!-- <span class="">{{ $t("t-color-title") }}: </span> -->
          <span>{{ $t('t_text_color') }}: </span>
          <el-color-picker v-model="dataOptionsCard.color" />
        </div>
        <div class="optionsCard__color-item">
          <!-- <span class="">{{ $t("t-background-color-title") }}: </span> -->
          <span>{{ $t('t_background_color') }}: </span>
          <el-color-picker v-model="dataOptionsCard.backgroundColor" />
        </div>
      </div>
      <div class="text-center mt-4">
        <el-button
          @click="updateOptionsCard()"
          type="primary"
          :loading="loadingBtn"
          >{{$t('t-confirm')}}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
.optionsCard {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & label {
      margin-bottom: 0;
    }
  }

  &__color {
    margin-top: 20px;
    display: flex;

    &-item {
      margin-right: 15px;
    }
  }
}

.options {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
