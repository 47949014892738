<template>
  <div>
    <i
      :style="`font-size: ${props.fontSize}px; opacity: ${props.opacity}; color: ${props.color}`"
      class="bx bx-loader-alt loading-icon"
    ></i>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps({
  fontSize: String,
  opacity: String,
  color: String,
});
</script>
<style scoped>
@keyframes xoayvong {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
.loading-icon {
  animation: xoayvong 2s linear 0s infinite;
}
</style>