<template>
  <el-tabs v-model="activeName" class="demo-tabs" type="border-card">
    <el-tab-pane :label="$t('t_plain_color')" name="first">
      <el-alert
        type="success"
        v-if="copySuccess"
        class="alert"
        center
        show-icon
        :closable="false"
      >
        <span slots="title"
          >{{ $t('t-copy-card-item') }}: <span class="name-color">{{ nameColor }}</span></span
        >
      </el-alert>
      <div class="d-flex mt-3">
        <b-row>
          <div
            class="bgColor"
            v-for="(item, index) in dataColor.colorDefault"
            :key="item.id"
          >
            <div
              class="bgColor__bg"
              :style="`background-color: ${item}`"
              @click="copyClipBoard(item, index)"
              :title="$t('t_click_to_copy')"
            ></div>
            <div
              class="bgColor__name"
              @click="copyClipBoard(item, index)"
              :title="$t('t_click_to_copy')"
            >
              {{ item }}
            </div>
          </div>
        </b-row>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Gradient" name="second">
      <el-alert
        type="success"
        v-if="copySuccess"
        class="alert"
        center
        show-icon
        :closable="false"
      >
        <span slots="title"
          >{{ $t('t-copy-card-item') }}: <span class="name-color">{{ nameColor }}</span></span
        >
      </el-alert>
      <div class="d-flex mt-3">
        <b-row>
          <div
            class="bgColor bgColor__gradient"
            v-for="(item, index) in dataColor.colorDefaultGradientBarChart"
            :key="item.id"
          >
            <div class="bgColor__gradient--first">
              <div
                class="bgColor__bg bgColor__gradient-button"
                :style="`background: linear-gradient(180deg, ${item[0]} 0%, ${item[1]} 100%)`"
              ></div>
              <div class="nameColorGradient">
                <div
                  class="bgColor__name bgColor__name--first"
                  :title="$t('t_click_to_copy')"
                  @click="copyClipBoard(item[0], index)"
                >
                  {{ item[0] }}
                </div>
                <div
                  class="bgColor__name"
                  :title="$t('t_click_to_copy')"
                  @click="copyClipBoard(item[1], index)"
                >
                  {{ item[1] }}
                </div>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script setup>
import { ref } from "vue";
import dataColor from "./CardItemsConfig/dataFormCardItemsConfig";

const activeName = ref("first");
const copySuccess = ref(false);
const nameColor = ref("");
const copyClipBoard = async (item) => {
  nameColor.value = "";
  try {
    await navigator.clipboard.writeText(item);
    nameColor.value = item;
    copySuccess.value = true;
    setTimeout(() => {
      copySuccess.value = false;
    }, 1000);
  } catch (err) {
    console.error("Failed to copy: ", err);
    nameColor.value = "";
  }
};
</script>
<style scoped lang="scss">
.demo-tabs {
  position: relative;
}
.alert {
  position: absolute;
  top: 5px;
  padding: 5px;
  width: 200px;
  left: 50%;
  transform: translate(-50%);
}
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.bgColor {
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;

  &__gradient {
    flex-direction: row;
    padding: 0 6px;
    justify-content: center;
    &--first,
    &--second {
      text-align: center;
    }
    &--first {
      margin-right: 5px;
    }
    &--second {
      margin-left: 5px;
    }
  }
  &__bg {
    height: 40px;
    width: 80px;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
  }
  &__gradient-button {
    width: 160px;
    cursor: default;
  }
  &__name {
    color: #434343;
    font-size: 14px;
    cursor: pointer;
    &--first {
      margin-right: 5px;
    }
  }
}
.nameColorGradient {
  display: flex;
  justify-content: space-around;
}
.name-color {
  font-size: 14px;
  font-weight: 600;
}
</style>
