import { defineStore } from "pinia";
import mushroom from "cem-probe-api";

export const listIcons = defineStore({
  id: "listIcons",
  state: () => ({
    arrayFileId: [],
  }),
  actions: {
    async getListIcons() {
      try {
        const response = await mushroom.icon.getAllAsync();
        this.arrayFileId = response.result
          .map((item) => item.file_id)
          .filter((item) => item);
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  getters: {
    listIcons: (state) => {
      return state.arrayFileId;
    },
  },
});
