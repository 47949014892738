<template>
  <div :id="'cem-card-item-inner-' + props.item.id" style="width: 100%"></div>
  <div :id="'tooltip-card-item-' + props.item.id" class="tooltip-card-item">
    <div class="tooltip-card-item__header">
      <p>{{ titleTooltip }}</p>
      <i class="ri-close-circle-line" @click="closeTooltipCardItem"></i>
    </div>
    <div class="tooltip-card-item__content" v-html="markdownToHTML(contentTooltip)" v-if="contentTooltip"></div>
    <div class="tooltip-card-item__content--nodata" v-else>{{ $t("t_no_description") }}</div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, watch, ref, reactive } from "vue";
import MethodService from "../../service/MethodService";
import Data from "../../components/CardItemsConfig/dataFormCardItemsConfig";
import { marked } from "marked";
import * as echarts from "echarts";
import { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";
const colorDefault = reactive({
  value: MethodService.copyObject(Data.colorDefault),
});
const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
  dataChartOption: Object,
});
const titleTooltip = ref();
const contentTooltip = ref();
const colorGradient = ref([]);
const rotateLabel = ref(0);
const chartTitle = ref();
const chartUI = reactive({
  smooth: false,
});
const chart_link = ref("");
const closeTooltipCardItem = () => {
  const tooltipDom = document.getElementById(
    `tooltip-card-item-${props.item.id}`
  );
  tooltipDom.classList.remove('showTooltip')
  titleTooltip.value = undefined
  contentTooltip.value = undefined
}
const markdownToHTML = (text) => {
  if (!text) return
  text = text.toString()
  return marked(text);
}
const renderDataValue = (data, indexData) => {
  let arr = data.data.map((row) => row[indexData]) || [];
  let newData = [];
  if (arr.length > 0)
    arr.forEach((item, index) => {
      newData.push({
        value: !item || item === "-" || item === "NA" ? null : Number(item),
        symbol:
          (arr[index + 1] === "" || !arr[index + 1]) && index + 1 < arr.length
            ? "circle"
            : "none",
      });
    });
  return newData;
};
const renderColor = (index) => {
  let color = "";
  if (colorGradient.value?.length > 0) {
    if (!colorGradient.value[index - 1]?.line_color) {
      color = colorDefault.value[index - 2];
    } else color = colorGradient.value[index - 1]?.line_color;
  } else color = [colorDefault.value[index - 1]];

  return color;
};
const render = (data) => {
  if (!data || data.length == 0) return;

  const chartDom = document.getElementById(
    `cem-card-item-inner-${props.item.id}`
  );
  const tooltipDom = document.getElementById(
    `tooltip-card-item-${props.item.id}`
  );
  const myChart = echarts.init(chartDom);
  const option = {
    grid: {
      left: "3%",
      top: chartTitle.value.show ? 40 : 20,
      right: 20,
      bottom: 20,
      containLabel: true,
    },
    title: chartTitle.value.show
      ? {
        text: chartTitle.value.text,
        textStyle: {
          fontSize: chartTitle.value.textStyle.fontSize,
          color: chartTitle.value.textStyle.color,
        },
      }
      : null,
    tooltip: {
      trigger: "axis",
      backgroundColor: "#1c1c1c",
      borderColor: "#1C1C1C",
      borderRadius: 14,
      textStyle: {
        color: "#fff",
        fontSize: 12,
      },
      axisPointer: {
        type: "shadow",
        label: {
          show: false, // lable trục ngang không bị ăn theo setting này
          formatter: function (params) {
            return data.columns[0].title + ": " + params.value;
          },
        },
      },
    },
    xAxis: {
      type: "category",
      data: data.data?.map((col) => col[0]),
      axisLabel: {
        rotate: rotateLabel.value,
        color: "#1c1c1c",
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        color: "#1c1c1c",
      },
      min: props.dataChartOption
        ? props.dataChartOption.y_axis.min
        : props.item.chart.y_axis.min,
      max: props.dataChartOption
        ? props.dataChartOption.y_axis.max
        : props.item.chart.y_axis.max,
      splitLine: {
        lineStyle: {
          color: "#D9E5FF",
          type: "dashed",
          width: 1.5,
        },
      },
    },
    series: [
      {
        name: data.columns[1].title,
        // data: data.data.map((row) => row[1]), => thử sửa lại cách hiển thị các vị trí k có giá trị là 1 điểm
        data: renderDataValue(data, 1),

        type: "line",
        showSymbol: true,
        symbolSize: 5,
        smooth: chartUI.smooth,
        // thêm markLine trường hợp có ....
        markArea: {
          data: data?.alert?.data?.map((item) => {
            return [
              {
                name: item.label,
                xAxis: item.xvalue_from,
                // màu cột
                itemStyle: {
                  color: item.bgcolor,
                },
                // màu chữ
                label: {
                  color: item.textcolor,
                  name: item.tooltip
                },
              },
              {
                xAxis: item.xvalue_to,
              },
            ];
          }),
        },
      },
    ],
    color: renderColor(1),
  };
  myChart.setOption(option);
  myChart.getZr().on("dblclick", function (event) {
    if (!event.target) {
      if (chart_link.value) window.open(chart_link.value);
    }
  });
  myChart.on("click", function (params) {
    if (!params) return
    if (params.componentType === 'markArea') {
      titleTooltip.value = params.data?.name || undefined
      contentTooltip.value = params.data?.label?.name.replace(/\n/g,'\n\n') || undefined
      if (titleTooltip.value && contentTooltip.value) tooltipDom.classList.add('showTooltip')
    }
  })
};

onMounted(() => {
  chartUI.smooth = props.dataChartOption
    ? props.dataChartOption.smooth
    : props.item.chart.smooth;
  chartTitle.value = props.dataChartOption
    ? props.dataChartOption.title
    : props.item.chart.title;
  colorGradient.value = props.dataChartOption
    ? props.dataChartOption.data_serials
    : props.item.chart.data_serials;
  rotateLabel.value = props.dataChartOption
    ? props.dataChartOption.y_axis.axisLabel?.rotate ?? 0
    : props.item.chart.y_axis.axisLabel?.rotate ?? 0;
  chart_link.value = props.dataChartOption
    ? props.dataChartOption.chart_link
    : props.item.chart.chart_link;
  render(props.itemData);
});
watch(
  () => props.itemData,
  (data) => render(data)
);
</script>
<style scoped></style>
